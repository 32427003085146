<template>
  <div>
    <!-- 业主维度--业主承诺管理 -->
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
    ></s-search>
    <div class="table-button">
      <a-button
        type="plain"
        icon="cloud-download"
        @click="handleExport"
        v-if="
          isShowBtns.indexOf('OwnerPromise-/api/worker/attendanceexport') > -1
        "
        >导出</a-button
      >
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >

      <columns-set
        tableName="OwnerPromise"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
      <s-table
        ref="table"
        size="small"
        rowKey="serial"
        :columns="tableColumns"
        :data="loadData"
        :showPagination="true"
        :scroll="{ x: 800, y: 'calc(82vh - 150px )' }"
        tableLayout="fixed"
        @change="changeTable"
      ></s-table>
    </div>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetAlarmEvent } from "@/api/image";

// 搜索条件
const queryItems = [
  {
    label: "姓名",
    value: "w_event_name",
    type: "input",
    defaultValue: "",
  },
  {
    label: "分公司",
    value: "w_event_name",
    type: "select",
    selectOption: "CompanyList",
  },
  {
    label: "时间段",
    type: "dateRange",
    range: ["a_time_start", "a_time_end"],
    // keyType: "",
    defaultValue: [],
  },
  {
    label: "工种",
    value: "w_event_name",
    type: "select",
    selectOption: "WorkType",
  },
  {
    label: "工地名称",
    value: "w_event_name",
    type: "input",
    defaultValue: "",
  },
];
const columns = [
  {
    title: "姓名",
    width: "80px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    align: "center",
  },
];
const orderParam = [
  "bc_name",
  "ws_name",
  "e_events",
  "eventworker_type",
  "eventworker_post",
  "ws_gj_name",
  "ws_jl_name",
  "e_time_start",
  "e_time_end",
  "w_event_name",
  "pageNo",
  "pageSize",
];

export default {
  name: "OwnerPromise",
  components: {
    STable,
    SSearch,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {},
      isShowBtns: [],
    };
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("OwnerPromiseColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.pageNo = parameter.pageNo;
        this.pageSize = parameter.pageSize;
      } else {
      }
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);

      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetAlarmEvent(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;

        return res.data;
      });
    },
    fetchUsers(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true);
    },
    changeTable(pagination, filters, sorter, { currentDataSource }) {
      console.log(pagination, filters, sorter, { currentDataSource });
    },
    handleExport() {
      this.confirmLoading = false;
      this.visible = true;
    },
  },
};
</script>
<style lang="less" scoped>
</style>